import { ServerResponseData } from 'app/models/api/response';
import { User, UserRole } from 'app/models/api/user';
import { ApiService } from 'app/services/api/api.service';
import { map } from 'rxjs/operators';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { inject, Injectable } from '@angular/core';
import { SomeWeightsTypes } from 'app/components/user-profile/matches-tab/matches-tab.component';
import { PSPType } from 'app/models/api/payment';

export interface SearchParams {
    email: string;
    firstName: string;
    lastName: string;
    placeUrl: string;
    roles: UserRole[];
    paymentPlatforms: PSPType[];
    blocked: boolean;
    quarantined: boolean;
    hidden: boolean;
    male: boolean;
    premium: boolean;
}

export interface UserLog {
    created_at: string;
    label: string;
    message: string;
}

export type EmailTypes =
    | 'change-avatar'
    | 'complete-registration-reminder'
    | 'forgot-password'
    | 'login-link'
    | 'matchmail'
    | 'non-response-victim'
    | 'payment-cancellation'
    | 'personal-data-warning'
    | 'reactivation'
    | 'underaged'
    | 'user-activation'
    | 'user-activation-2-parents'
    | 'user-activation-2-sitters';

@Injectable({
    providedIn: 'root',
})
export class UserApiService {
    private readonly apiService = inject(ApiService);
    private readonly responseParser = inject(ResponseParser);

    searchUsers(searchParams: Partial<SearchParams>) {
        return this.apiService
            .get('/gem/sitly-users', { params: { filter: searchParams } })
            .pipe(map(response => this.responseParser.parseObject<User[]>(response)));
    }

    getMatchesForUser(user: User, relevanceSortingWeights: SomeWeightsTypes) {
        return this.apiService
            .get(`/gem/sitly-users/${user.id}/matches`, {
                params: {
                    include: user.isParent ? 'recommendations' : 'children',
                    relevanceSortingWeights,
                    sort: 'relevance',
                    filter: { role: user.isParent ? 'babysitter' : 'parent' },
                    page: {
                        number: 1,
                        size: 100,
                    },
                },
            })
            .pipe(map(response => this.responseParser.parseObject<User[], { totalCount: number }>(response)));
    }

    getUser(userId: string) {
        return this.apiService.get(`/gem/sitly-users/${userId}`).pipe(map(response => this.responseParser.parseObject<User>(response)));
    }

    getUserLogs(userId: string) {
        return this.apiService.get<{ meta: { total: number }; data: UserLog[] }>(`/gem/sitly-users/${userId}/logs`);
    }

    updateUser(userId: string, user: Partial<User>) {
        return this.apiService
            .patch(`/gem/sitly-users/${userId}`, { body: user })
            .pipe(map(response => this.responseParser.parseObject<User>(response)));
    }

    deleteUser(userId: string) {
        return this.apiService.delete(`/gem/sitly-users/${userId}`);
    }

    deleteUserPhoto(userId: string, photoId: string) {
        return this.apiService.delete(`/gem/sitly-users/${userId}/photos/${photoId}`);
    }

    postNote(userId: string, content: string) {
        return this.apiService
            .post(`/gem/sitly-users/${userId}/notes`, { body: { content } })
            .pipe(map(response => this.responseParser.parseObject<User>(response)));
    }

    updateNote(userId: string, content: string) {
        return this.apiService
            .patch(`/gem/sitly-users/${userId}`, { body: { notes: content } })
            .pipe(map(response => this.responseParser.parseObject<User>(response)));
    }

    getTempToken(userId: string) {
        return this.apiService
            .post(`/gem/sitly-users/${userId}/temp-tokens`)
            .pipe(map(response => (response.data as ServerResponseData).attributes.token as string));
    }

    sendWarningEmail(userId: string) {
        return this.apiService.post(`/gem/sitly-users/${userId}/warning-email`);
    }

    sendEmail(userId: string, body: Record<string, string> & { emailType: EmailTypes }) {
        return this.apiService.post(`/gem/sitly-users/${userId}/email`, { body });
    }

    createUser(email: string, password: string, firstName: string, lastName: string, role: string) {
        return this.apiService
            .post('/gem/sitly-users', {
                body: { email, password, firstName, lastName, role },
            })
            .pipe(map(response => this.responseParser.parseObject<User>(response)));
    }

    getAuthToken(email: string, password: string) {
        return this.apiService.post('/tokens', { body: { email, password } });
    }

    updateSitlyUser(user: Partial<User>, token?: string) {
        return this.apiService
            .patch('/users/me', { body: user, headers: { Authorization: `Bearer ${token}` } })
            .pipe(map(response => this.responseParser.parseObject<User>(response)));
    }
}
