const getMatchesCount = (str: string, template: RegExp) => {
    let count = 0;

    while (template.exec(str)) {
        count++;
    }

    return count;
};

const getTemplateMatches = (str: string, template: RegExp) => {
    const resArray: string[] = [];

    let res = template.exec(str);
    while (res) {
        resArray.push(res[0]);
        res = template.exec(str);
    }

    return resArray.reduce(
        (accumulator, value) => {
            accumulator[value] = (accumulator[value] ?? 0) + 1;
            return accumulator;
        },
        {} as Record<string, number>,
    );
};

export const validators: ((source: string, target: string) => string[] | undefined)[] = [
    // translation length
    (source: string, target: string) => {
        return target.length / source.length >= 1.15
            ? [`This translation is ${((target.length / source.length) * 100.0 - 100).toFixed(0)}% longer than control version`]
            : undefined;
    },

    // template strings format
    (source: string, target: string) => {
        const templateFormat = /{{\w+}}/g;

        const templateResult: string[] = [];
        const sourceTemplateMatches = getTemplateMatches(source, templateFormat);
        const targetTemplateMatches = getTemplateMatches(target, templateFormat);

        Object.keys(sourceTemplateMatches).forEach(key => {
            const sourceCount = sourceTemplateMatches[key] ?? 0;
            const targetCount = targetTemplateMatches[key] ?? 0;
            if (sourceCount - targetCount > 0) {
                templateResult.push(`Template strings format doesn't match, please check you translation for ${key}`);
            }
        });

        Object.keys(targetTemplateMatches).forEach(key => {
            const sourceCount = sourceTemplateMatches[key] ?? 0;
            const targetCount = targetTemplateMatches[key] ?? 0;
            if (targetCount > 0 && targetCount > sourceCount) {
                let targetUsage = '';
                if (targetCount === 2) {
                    targetUsage = 'twice';
                } else if (targetCount > 2) {
                    targetUsage = `${targetCount} times`;
                }

                let sourceUsage = 'not found';
                if (sourceCount === 1) {
                    sourceUsage = 'only once';
                } else if (sourceCount === 2) {
                    sourceUsage = 'only twice';
                } else if (sourceCount > 2) {
                    targetUsage = `${sourceCount} times`;
                }

                templateResult.push(
                    `Template strings format doesn't match, ${key} is used ${targetUsage} in translation, but ${sourceUsage} in source`,
                );
            }
        });

        return templateResult;
    },

    // leading or trailing spaces/line-breaks
    (_source: string, target: string) => {
        const result = [];
        if (target.startsWith(' ')) {
            result.push('Translation has leading spaces');
        }
        if (target.endsWith(' ')) {
            result.push('Translation has trailing spaces');
        }
        if (target.startsWith('\n')) {
            result.push('Translation has leading line-breaks');
        }
        if (target.endsWith('\n')) {
            result.push('Translation has trailing line-breaks');
        }
        return result;
    },

    // mismatched special characters
    (source: string, target: string) => {
        const patterns = [
            { regexp: /[\n|\r]/g, errorMsg: 'new lines' },
            { regexp: /:/g, errorMsg: 'colon' },
            { regexp: /;/g, errorMsg: 'semicolon' },
            { regexp: /!/g, errorMsg: 'exclamation mark' },
            { regexp: /\?/g, errorMsg: 'question mark' },
            { regexp: /\./g, errorMsg: 'full stop' },
            { regexp: /\[/g, errorMsg: 'left square bracket' },
            { regexp: /\]/g, errorMsg: 'right square bracket' },
            { regexp: /\{/g, errorMsg: 'left curly bracket' },
            { regexp: /\}/g, errorMsg: 'right curly bracket' },
            { regexp: /\(/g, errorMsg: 'left round bracket' },
            { regexp: /\)/g, errorMsg: 'right round bracket' },
            { regexp: /%s/g, errorMsg: 'placeholders %s' },
        ];

        return patterns.reduce((accumulator, pattern) => {
            const sourceMatchesCount = getMatchesCount(source, pattern.regexp);
            const targetMatchesCount = getMatchesCount(target, pattern.regexp);
            if (sourceMatchesCount !== targetMatchesCount) {
                accumulator.push(
                    `Mismatched ${pattern.errorMsg}: source has ${sourceMatchesCount}, but translation has ${targetMatchesCount}`,
                );
            }
            return accumulator;
        }, [] as string[]);
    },

    // double white-space characters
    (_source: string, target: string) => {
        const res = /\s{2,}/g.exec(target);
        if (res) {
            const leadingWord = target
                .substring(0, res.index)
                .split(/(\s+)/)
                .reverse()
                .find(item => item.trim().length > 0);
            const trailingWord = target
                .substring(res.index + 1, target.length)
                .split(/(\s+)/)
                .find(item => item.trim().length > 0);

            return leadingWord && trailingWord ? [`Multiple spaces between words "${leadingWord}" and "${trailingWord}"`] : [];
        }

        return [];
    },
];
