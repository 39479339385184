<div class="profile-section">
    <div class="section-row status-section" [class.success]="showEmailConfirmation()">
        Status:
        <div *ngIf="sendingEmail()">sending email...</div>
        <div *ngIf="showEmailConfirmation()" class="email-sent-confirmation">Email sent</div>
    </div>

    <div class="section-header">Send:</div>
    <i>(all emails except "Warning email" can be sent only to &#64;sitly.com emails)</i>
    <br />
    <button class="btn-link" (click)="sendEmail('personal-data-warning')">Warning email</button>
    <br />

    <div *ngIf="user.email?.endsWith('@sitly.com')" class="section-row">
        <button class="btn-link" (click)="sendEmail('change-avatar')">Change avatar</button>
        <button *ngIf="!user.completed" class="btn-link" (click)="sendEmail('complete-registration-reminder')">
            Complete registration reminder
        </button>
        <button class="btn-link" (click)="sendEmail('forgot-password')">Forgot password</button>
        <button class="btn-link" (click)="sendEmail('login-link')">Login link</button>
        <button class="btn-link" (click)="sendEmail('matchmail')">Matchmail</button>
        <button *ngIf="!user.isParent" class="btn-link" (click)="sendEmail('non-response-victim')">Non response victim</button>
        <button class="btn-link" (click)="sendEmail('payment-cancellation')">Payment cancellation</button>
        <button class="btn-link" (click)="sendEmail('reactivation')">Reactivation email</button>
        <button class="btn-link" (click)="sendEmail('underaged')">Underaged</button>
        <div>
            <input #userActivationEmail type="email" placeholder="User activation email" />
            <button class="btn-link btn-send" (click)="sendEmail('user-activation', { userActivationEmail: userActivationEmail.value })">
                Send
            </button>
        </div>
        <div>
            <input #userActivation2Email type="email" placeholder="User activation 2 email" />
            <button
                class="btn-link btn-send"
                (click)="
                    sendEmail(user.isParent ? 'user-activation-2-parents' : 'user-activation-2-sitters', {
                        userActivationEmail: userActivation2Email.value
                    })
                "
            >
                Send
            </button>
        </div>
    </div>

    <span class="section-header">All emails list and statuses:</span>
    <ul class="info-section-list">
        <li class="supported">change-avatar</li>
        <li class="easy-to-trigger">chat-message-notification-v2 - Can be manually triggered in regular flow</li>
        <li class="supported">complete-registration-reminder (only not complete users)</li>
        <li class="supported">forgot-password</li>
        <li class="supported">login-link</li>
        <li class="supported">matchmail</li>
        <li class="supported">non-response-victim (only fosters)</li>
        <li class="supported">payment-cancellation</li>
        <li class="easy-to-trigger">payment-invoice - Can be manually triggered in regular flow</li>
        <li class="supported">personal-data-warning</li>
        <li class="supported">reactivation (welcome email) - Can be manually send</li>
        <li class="easy-to-trigger">recommendation - Can be manually triggered in regular flow</li>
        <li class="supported">underaged</li>
        <li class="supported">user-activation</li>
    </ul>
</div>
