<ng-template #PaginatorRef>
    <div class="pagination" *ngIf="totalCount > 1">
        <mat-paginator
            [length]="totalCount"
            [pageSize]="100"
            [pageIndex]="paginationPage - 1"
            [showFirstLastButtons]="true"
            (page)="loadPage($event); container.scrollTo({ top: 0, behavior: 'smooth' })"
            aria-label="Select page"
        >
        </mat-paginator>
    </div>
</ng-template>

<div class="page-container" #container>
    <form [formGroup]="feedbackFilterForm">
        <label>
            Feedbacks from:
            <select name="webroleId" formControlName="selectedRoleControl">
                <option *ngFor="let role of roleOptions" [value]="role.id">{{ role.name }}</option>
            </select>
        </label>
    </form>
    <ng-template [ngTemplateOutlet]="PaginatorRef"></ng-template>
    <div class="feedback-table">
        <div class="feedback-header">
            <div>Description</div>
            <div>Category</div>
            <div>Created</div>
            <div>User</div>
        </div>
        <div class="feedback-item" *ngFor="let feedback of feedbacks">
            <td>{{ feedback.description }}</td>
            <td>{{ feedback.category }}</td>
            <td>{{ feedback.created }}</td>
            <td>
                <a *ngIf="feedback.user" [routerLink]="['/', storageService.countryCode, 'users', feedback.user.id]" target="_blank">
                    {{ feedback.user.id }}
                </a>
            </td>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="PaginatorRef"></ng-template>
</div>
