import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { BaseUserProfileTabComponent } from 'app/components/user-profile/base-user-profile-tab.component';
import { EmailTypes } from 'app/services/api/user.api.service';

@Component({
    selector: 'emails-tab',
    templateUrl: './emails-tab.component.html',
    styleUrls: ['./emails-tab.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsTabComponent extends BaseUserProfileTabComponent {
    readonly showEmailConfirmation = signal(false);
    readonly sendingEmail = signal(false);

    sendEmail(emailType: EmailTypes, extra?: Record<string, string>) {
        this.sendingEmail.set(true);
        this.userApiService.sendEmail(this.user.id, { emailType, ...extra }).subscribe({
            next: _ => {
                this.sendingEmail.set(false);
                this.showEmailConfirmation.set(true);
                setTimeout(() => {
                    this.showEmailConfirmation.set(false);
                }, 3_000);
            },
            error: _ => {
                this.sendingEmail.set(false);
            },
        });
    }
}
